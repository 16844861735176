import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import meter1 from '../assets/img/meter1.svg';
import meter2 from '../assets/img/meter2.svg';
import meter3 from '../assets/img/meter3.svg';
import colorSharp from '../assets/img/color-sharp.webp';

export const Skills = () => {
    const responsive = {
        superLargeDesktop: {

            breakpoint: { max: 4000, min: 3000 },
            items: 5
        },
        desktop: {
            breakpoint: { max: 3000, min: 1024 },
            items: 3
        },
        tablet: {
            breakpoint: { max: 1024, min: 464 },
            items: 2
        },
        mobile: {
            breakpoint: { max: 464, min: 0 },
            items: 1
        }
    };

    return (
        <section className="skill" id="skills">
            <Container>
                <Row>
                    <Col>
                        <div className="skill-bx  wow zoomIn">
                            <h2>
                                Skills
                            </h2>
                            <p>Unleashing the Power of Web Technology: Crafting Engaging, Interactive, and User-Centric Experiences.</p>
                            <Carousel responsive={responsive} infinite={true} className="skill-slider owl-carousel owl-theme">
                                <div className="item">
                                    <img src={meter1} alt="percentage in React" />
                                    <h5>React</h5>
                                </div>
                                <div className="item">
                                    <img src={meter1} alt="percentage in Bootstrap" />
                                    <h5>Bootstrap</h5>
                                </div>
                                <div className="item">
                                    <img src={meter3} alt="percentage in Javascript" />
                                    <h5>Javascript</h5>
                                </div>
                                <div className="item">
                                    <img src={meter1} alt="percentage in API & Authorization" />
                                    <h5>API & Authorization</h5>
                                </div>
                                <div className="item">
                                    <img src={meter2} alt="percentage in Node.js" />
                                    <h5>Node.js</h5>
                                </div>
                                <div className="item">
                                    <img src={meter2} alt="percentage in Express" />
                                    <h5>Express</h5>
                                </div>
                                <div className="item">
                                    <img src={meter3} alt="percentage in MongoDB" />
                                    <h5>MongoDB</h5>
                                </div>
                            </Carousel>
                        </div>
                    </Col>
                </Row>
            </Container>
            <img className="background-image-left" alt="background-left" src={colorSharp} />
        </section>
    )
};
